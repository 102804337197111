import React, { useMemo } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { grey } from '@mui/material/colors';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { QUERY_KEYS, IKeyLevelPayload, IUser } from 'utilities';
import { useHandleError, useModalAlert } from 'hooks';
import { useReloadUser } from '../../hooks/useLoadUser';
import { kycLevelTwoUpdate, confirmKycLevelTwo } from 'api';
import TextInput from '../form-components/text-input';
import Button from '../button';
import ModalLayout from 'components/layout/modal-layout';
import TextPlaceholder from 'components/partials/custom-text-input-holder';

type Props = {
	callback?: () => void;
};

interface IVerificationDetails {
	verificationId: string;
	translationReponse: {
		firstname: string;
		lastname: string;
		middlename: string;
	};
}

const IdentityVerificationForm = ({ callback }: Props) => {
	const theme = useTheme();
	const handleError = useHandleError();
	const styles = useStyles(theme);
	const modal = useModalAlert();
	const queryClient = useQueryClient();
	const reloadUser = useReloadUser();

	const [verificationDetails, setVerificationDetails] =
		React.useState<IVerificationDetails | null>(null);

	const verifiedName = useMemo(() => {
		if (verificationDetails && Object.keys(verificationDetails).length > 0) {
			return `${verificationDetails.translationReponse?.firstname} ${verificationDetails.translationReponse?.lastname} ${verificationDetails.translationReponse?.middlename}`;
		}

		return '';
	}, [verificationDetails]);

	const isVerified = Boolean(
		verificationDetails && Object.keys(verificationDetails).length > 0
	);

	const initialValues: Partial<IKeyLevelPayload> = {
		payload: '',
		type: 'NIN',
	};

	const validationSchema = yup.object().shape({
		payload: yup
			.string()
			.matches(/\d{11}/, 'NIN must be a 11 digit nummber')
			.required('Enter NIN'),
	});

	const { isLoading, mutate } = useMutation(kycLevelTwoUpdate, {
		onSettled: (data, error) => {
			if (error) {
				const res = handleError({ error });
				if (res && res.message) {
					const regExp = new RegExp('PAYLOAD exists', 'ig');
					const message = res.message;

					const responseMsg = regExp.test(message)
						? 'NIN has already been used.'
						: message;

					modal({
						type: 'error',
						title: 'Identity verification',
						message: responseMsg,
						primaryButtonText: 'Close',
						onClickPrimaryButton: () => modal(null),
					});
				}
			}
			if (data && data.success) {
				const details = data.payload;
				setVerificationDetails(details);
			}
		},
	});

	// Confirm NIN verification mutation

	const {
		isLoading: isConfirmingVerification,
		mutate: mutateConfirmVerification,
	} = useMutation(confirmKycLevelTwo, {
		onSettled: async (data, error) => {
			if (error) {
				const res = handleError({ error });
				if (res && res.message) {
					const message = res.message;

					modal({
						type: 'error',
						title: 'Identity verification',
						message,
						primaryButtonText: 'Close',
						onClickPrimaryButton: () => modal(null),
					});
				}
			}
			if (data && data.success) {
				setVerificationDetails(null); // Clear verification details
				resetForm();
				// queryClient.invalidateQueries(QUERY_KEYS.Me);

				modal({
					type: 'success',
					title: 'Identify Verification',
					message: data.message,
					primaryButtonText: 'Close',
					onClickPrimaryButton: () => {
						modal(null);
						queryClient.invalidateQueries(QUERY_KEYS.Me);
						callback?.();
					},
				});

				await reloadUser();
			}
		},
	});

	const {
		touched,
		values,
		errors,
		handleSubmit,
		handleChange,
		resetForm,
		setFieldValue,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			if (isVerified) {
				handleConfirmVerification();
				return;
			}
			mutate(values);
		},
	});

	const handleConfirmVerification = () => {
		mutateConfirmVerification({
			id: verificationDetails?.verificationId as string,
			data: {
				confirmed: true,
			},
		});
	};

	return (
		<>
			<Box>
				<Box sx={{ marginBottom: theme.spacing(4) }}>
					<Typography variant={'body1'}>
						Please type in your National Identification Number (NIN) to verify
						you Identity.
						{/* along side
					the rest of your details to verify your identity. */}
					</Typography>
				</Box>
				<Box style={styles.form as any} component={'form'}>
					<TextInput
						fullWidth
						labelText={'NIN'}
						placeholder={'Enter NIN Number'}
						error={errors && touched.payload && errors.payload ? true : false}
						helperText={errors && touched.payload && errors.payload}
						value={values.payload}
						onChange={(e) => {
							const value = e.target.value;
							setFieldValue('payload', value);

							setVerificationDetails(null); // Clear verified fields on text change
						}}
					/>
					{isVerified && verifiedName && (
						<Box>
							<Typography
								style={styles.label}
								component={'label'}
								variant={'body1'}
							>
								Given name
							</Typography>
							<TextPlaceholder text={verifiedName} />
						</Box>
					)}
					{/* <Box
					sx={{
						display: 'grid',
						gridTemplateColumns: {
							xs: '1fr',
							md: 'repeat(2, 1fr)',
						},
						gap: (theme) => theme.spacing(3),
					}}
				>
					<TextInput
						fullWidth
						labelText={'First name'}
						placeholder={'Enter your first name'}
						error={
							errors && touched.firstname && errors.firstname ? true : false
						}
						helperText={errors && touched.firstname && errors.firstname}
						value={values.firstname}
						onChange={handleChange('firstname')}
					/>
					<TextInput
						fullWidth
						labelText={'Last name'}
						placeholder={'Enter your last name'}
						error={errors && touched.lastname && errors.lastname ? true : false}
						helperText={errors && touched.lastname && errors.lastname}
						value={values.lastname}
						onChange={handleChange('lastname')}
					/>
				</Box> */}
					{/* <TextInput
					fullWidth
					labelText={'Pone number'}
					error={errors && touched.phone && errors.phone ? true : false}
					helperText={errors && touched.phone && errors.phone}
					placeholder={'Enter  your Phone number'}
					value={values.phone}
					onChange={handleChange('phone')}
				/> */}

					<Box>
						<Button
							fullWidth
							style={styles.btn}
							size={'large'}
							loading={isLoading || isConfirmingVerification}
							onClick={(e: React.FormEvent<HTMLButtonElement>) => {
								e.preventDefault();
								handleSubmit();
							}}
						>
							{isVerified ? 'Confirm' : values.payload ? 'Verify' : 'Submit'}
						</Button>
					</Box>
				</Box>
			</Box>
		</>
	);
};

const useStyles = (theme: any) => ({
	title: {
		fontWeight: '600',
		marginBottom: theme.spacing(2),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(3),
	},
	label: {
		marginBottom: theme.spacing(2),
		display: 'inline-block',
		fontWeight: '600',
	},
	btn: {
		backgroundColor: theme.palette.secondary.main,
		color: grey[50],
		fontWeight: '600',
	},
	btnOutline: {
		border: `1px solid ${theme.palette.secondary.main}`,
		fontWeight: '500',
	},
	emailText: {
		fontWeight: '600',
	},
});

export default IdentityVerificationForm;
